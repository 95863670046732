//
// Base styles
//

.accordion {
  // scss-docs-start accordion-css-vars
  --#{$prefix}accordion-color: #{$accordion-color};
  --#{$prefix}accordion-bg: #{$accordion-bg};
  --#{$prefix}accordion-transition: #{$accordion-transition};
  --#{$prefix}accordion--color: #{$accordion--color};
  --#{$prefix}accordion--width: #{$accordion--width};
  --#{$prefix}accordion--radius: #{$accordion--radius};
  --#{$prefix}accordion-inner--radius: #{$accordion-inner--radius};
  --#{$prefix}accordion-btn-padding-x: #{$accordion-button-padding-x};
  --#{$prefix}accordion-btn-padding-y: #{$accordion-button-padding-y};
  --#{$prefix}accordion-btn-color: #{$accordion-button-color};
  --#{$prefix}accordion-btn-bg: #{$accordion-button-bg};
  --#{$prefix}accordion-btn-icon: #{escape-svg($accordion-button-icon)};
  --#{$prefix}accordion-btn-icon-width: #{$accordion-icon-width};
  --#{$prefix}accordion-btn-icon-transform: #{$accordion-icon-transform};
  --#{$prefix}accordion-btn-icon-transition: #{$accordion-icon-transition};
  --#{$prefix}accordion-btn-active-icon: #{escape-svg($accordion-button-active-icon)};
  --#{$prefix}accordion-btn-focus--color: #{$accordion-button-focus--color};
  --#{$prefix}accordion-btn-focus-box-shadow: #{$accordion-button-focus-box-shadow};
  --#{$prefix}accordion-body-padding-x: #{$accordion-body-padding-x};
  --#{$prefix}accordion-body-padding-y: #{$accordion-body-padding-y};
  --#{$prefix}accordion-active-color: #{$accordion-button-active-color};
  --#{$prefix}accordion-active-bg: #{$accordion-button-active-bg};
  // scss-docs-end accordion-css-vars
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--#{$prefix}accordion-btn-padding-y) var(--#{$prefix}accordion-btn-padding-x);
  @include font-size($font-size-base);
  color: var(--#{$prefix}accordion-btn-color);
  text-align: left; // Reset button style
  background-color: var(--#{$prefix}accordion-btn-bg);
  : 0;
  @include -radius(0);
  overflow-anchor: none;
  @include transition(var(--#{$prefix}accordion-transition));

  &:not(.collapsed) {
    color: var(--#{$prefix}accordion-active-color);
    background-color: var(--#{$prefix}accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--#{$prefix}accordion--width)) 0 var(--#{$prefix}accordion--color); // stylelint-disable-line function-disallowed-list

    &::after {
      background-image: var(--#{$prefix}accordion-btn-active-icon);
      transform: var(--#{$prefix}accordion-btn-icon-transform);
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: var(--#{$prefix}accordion-btn-icon-width);
    height: var(--#{$prefix}accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--#{$prefix}accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--#{$prefix}accordion-btn-icon-width);
    @include transition(var(--#{$prefix}accordion-btn-icon-transition));
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    -color: var(--#{$prefix}accordion-btn-focus--color);
    outline: 0;
    box-shadow: var(--#{$prefix}accordion-btn-focus-box-shadow);
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--#{$prefix}accordion-color);
  background-color: var(--#{$prefix}accordion-bg);
  : var(--#{$prefix}accordion--width) solid var(--#{$prefix}accordion--color);

  &:first-of-type {
    @include -top-radius(var(--#{$prefix}accordion--radius));

    .accordion-button {
      @include -top-radius(var(--#{$prefix}accordion-inner--radius));
    }
  }

  &:not(:first-of-type) {
    -top: 0;
  }

  // Only set a -radius on the last item if the accordion is collapsed
  &:last-of-type {
    @include -bottom-radius(var(--#{$prefix}accordion--radius));

    .accordion-button {
      &.collapsed {
        @include -bottom-radius(var(--#{$prefix}accordion-inner--radius));
      }
    }

    .accordion-collapse {
      @include -bottom-radius(var(--#{$prefix}accordion--radius));
    }
  }
}

.accordion-body {
  padding: var(--#{$prefix}accordion-body-padding-y) var(--#{$prefix}accordion-body-padding-x);
}


// Flush accordion items
//
// Remove s and -radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-collapse {
    -width: 0;
  }

  .accordion-item {
    -right: 0;
    -left: 0;
    @include -radius(0);

    &:first-child { -top: 0; }
    &:last-child { -bottom: 0; }

    .accordion-button {
      &,
      &.collapsed {
        @include -radius(0);
      }
    }
  }
}
