// scss-docs-start alert-variant-mixin
@mixin alert-variant($background, $, $color) {
  --#{$prefix}alert-color: #{$color};
  --#{$prefix}alert-bg: #{$background};
  --#{$prefix}alert--color: #{$};

  @if $enable-gradients {
    background-image: var(--#{$prefix}gradient);
  }

  .alert-link {
    color: shade-color($color, 20%);
  }
}
// scss-docs-end alert-variant-mixin
