// stylelint-disable property-disallowed-list
// Single side -radius

// Helper function to replace negative values with 0
@function valid-radius($radius) {
  $return: ();
  @each $value in $radius {
    @if type-of($value) == number {
      $return: append($return, max($value, 0));
    } @else {
      $return: append($return, $value);
    }
  }
  @return $return;
}

// scss-docs-start -radius-mixins
@mixin -radius($radius: $-radius, $fallback--radius: false) {
  @if $enable-rounded {
    -radius: valid-radius($radius);
  }
  @else if $fallback--radius != false {
    -radius: $fallback--radius;
  }
}

@mixin -top-radius($radius: $-radius) {
  @if $enable-rounded {
    -top-left-radius: valid-radius($radius);
    -top-right-radius: valid-radius($radius);
  }
}

@mixin -end-radius($radius: $-radius) {
  @if $enable-rounded {
    -top-right-radius: valid-radius($radius);
    -bottom-right-radius: valid-radius($radius);
  }
}

@mixin -bottom-radius($radius: $-radius) {
  @if $enable-rounded {
    -bottom-right-radius: valid-radius($radius);
    -bottom-left-radius: valid-radius($radius);
  }
}

@mixin -start-radius($radius: $-radius) {
  @if $enable-rounded {
    -top-left-radius: valid-radius($radius);
    -bottom-left-radius: valid-radius($radius);
  }
}

@mixin -top-start-radius($radius: $-radius) {
  @if $enable-rounded {
    -top-left-radius: valid-radius($radius);
  }
}

@mixin -top-end-radius($radius: $-radius) {
  @if $enable-rounded {
    -top-right-radius: valid-radius($radius);
  }
}

@mixin -bottom-end-radius($radius: $-radius) {
  @if $enable-rounded {
    -bottom-right-radius: valid-radius($radius);
  }
}

@mixin -bottom-start-radius($radius: $-radius) {
  @if $enable-rounded {
    -bottom-left-radius: valid-radius($radius);
  }
}
// scss-docs-end -radius-mixins
